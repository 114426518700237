import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import ProjectPostSection from '../components/ProjectPage/ProjectPostSection'
import PageHeader from '../components/PageHeader'
import Layout from '../components/Layout'

// Export Template for use in CMS preview
export const ProjectIndexTemplate = ({
  title,
  subtitle,
  featuredImage,
  client,
  posts = []
}) => {
  return (
    <Fragment>
      <PageHeader
        title={title}
        subtitle={subtitle}
        backgroundImage={featuredImage}
      />
      <div className="container mx-auto pb-16">
        <div
          className="text-center section2 py-6"
          // data-sal="slide-up"
          // data-sal-duration="400"
          // data-sal-easing="ease"
          // data-sal-delay="50
          // 0"
        >
          <h2 className="text-2xl">Clients</h2>
          <div class="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow py-6">
            <div class="flex justify-center">
              <ul class="flex flex-wrap justify-center">
                {client.currentclient.map(({ featuredImage, clientig }) => (
                  <li className="mr-6">
                    <a href={clientig} target="_blank" rel="noreferrer">
                      <Img
                        fluid={featuredImage.childImageSharp.fluid}
                        className="w-32 h-32"
                        alt={`featured image thumbnail for post ${title}`}
                      />
                    </a>
                  </li>
                ))}
                {client.pastclient.map(({ featuredImage, pastclientig }) => (
                  <li className="mr-6">
                    <a href={pastclientig} target="_blank" rel="noreferrer">
                      <Img
                        fluid={featuredImage.childImageSharp.fluid}
                        className="w-32 h-32"
                        alt={`featured image thumbnail for post ${title}`}
                      />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {!!posts.length && (
          <div
            className="container pb-16"
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <div className="text-center section2 py-6">
              <h2 className="text-2xl">Our Works</h2>
            </div>
            <ProjectPostSection posts={posts} />
          </div>
        )}
      </div>
    </Fragment>
  )
}
// Export Default BlogIndex for front-end
const ProjectIndex = ({ data: { page, posts, postCategories } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <ProjectIndexTemplate
      {...page}
      {...page.fields}
      {...page.frontmatter}
      posts={posts.edges.map((post) => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
      postCategories={postCategories.edges.map((post) => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
    />
  </Layout>
)

export default ProjectIndex

export const pageQuery = graphql`
  ## Query for BlogIndex data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query ProjectIndex($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      fields {
        contentType
      }
      frontmatter {
        title
        excerpt
        template
        subtitle
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        client {
          currentclient {
            clientig
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 500, quality: 98) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        client {
          pastclient {
            pastclientig
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 500, quality: 98) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    posts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "project" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            tags
            categories {
              category
            }
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 500, maxHeight: 250, quality: 98) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    postCategories: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "blog/category" } } }
      sort: { order: ASC, fields: [frontmatter___title] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
